import React from 'react';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import logo from '../assets/images/logo.png';
import gavin from '../assets/images/gavin.png';
import natalie from '../assets/images/natalie.png';
import william from '../assets/images/william.jpg';
import theo from '../assets/images/theo.jpg';
import config from '../../config';

const IndexPage = () => (
  <Layout>

    <section id="banner">
      <div className="inner">

        <video id="background-video" autoplay="autoplay" loop muted >
          <source src="/videos/banner.mp4" type="video/mp4" />
        </video>

        <p><img src={logo} alt='Expedition Explore' style={{ width:'50%', maxWidth:'400px' }}/></p>
        <h2>{config.heading}</h2>
        <p>{config.subHeading1}<br />{config.subHeading2}</p>
        <ul class="icons">
          <li><a href="https://youtube.com/@expeditionexploreaus" class="icon brands fa-youtube fa-1x"><span class="label">YouTube</span></a></li>
          <li><a href="https://instagram.com/expeditionexploreaus" class="icon brands fa-instagram fa-1x"><span class="label">Instagram</span></a></li>
          <li><a href="https://facebook.com/expeditionexploreaus" class="icon brands fa-facebook fa-1x"><span class="label">Facebook</span></a></li>
          <li><a href="https://patreon.com/expeditionexplore" class="icon brands fa-patreon fa-1x"><span class="label">Patreon</span></a></li>
        </ul>
      </div>
      <Scroll type="id" element="one">
        <a href="#one" className="more">
          Learn More
        </a>
      </Scroll>
    </section>

    <section id="waystofollow" className="wrapper style3 special">
      <div className="inner">
        <header className="major">
          <h2>Ways to follow and support our journey</h2>
        </header>
        <ul className="features">
          <li className="icon brands fa-youtube">
            <h3><a href="https://youtube.com/@ExpeditionExploreAus?sub_confirmation=1" target='_blank' rel='noreferrer'>YouTube</a></h3>
            <p>
              We create YouTube videos of our travels.
            </p>
          </li>
          <li className="icon brands fa-instagram">
            <h3><a href="https://instagram.com/expeditionexploreaus" target='_blank' rel='noreferrer'>Instagram</a></h3>
            <p>
              Keep up to date with us between videos on Instagram.
            </p>
          </li>
          <li className="icon brands fa-facebook">
            <h3><a href="https://facebook.com/expeditionexploreaus" target='_blank' rel='noreferrer'>Facebook</a></h3>
            <p>
              Keep up to date with us between videos on Facebook.
            </p>
          </li>
          <li className="icon solid fa-shopping-cart">
            <h3><a href="https://shop.expeditionexploreaus.com" target='_blank' rel='noreferrer'>Online Store</a></h3>
            <p>
              Help support us by buying something from our online store
            </p>
          </li>
          <li className="icon brands fa-patreon">
            <h3><a href="https://patreon.com/expeditionexplore" target='_blank' rel='noreferrer'>Patreon</a></h3>
            <p>
              A little bit of help goes a long way to helping us do more.
            </p>
          </li>
          <li className="icon fa-heart">
            <h3>Blog</h3>
            <p>
              <strong>COMING SOON</strong><br />
              We write about tracks we've found so you can try them too!
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section id="aboutus" className="wrapper style1 special">
      <div className="inner">
        <header className="major">
          <h2>
            About Us
          </h2>
          <p>
            We're a family of four, looking to get out and explore. In March of 2022 we bought our first four wheel drive, only to get caught up in delays for it to arrive 18 months later in October 2023.  Since then we’ve been learning the ropes, taking our small family out on adventures to discover Australia - starting in our home state of Victoria.  We’d love for you to come along with us as we document our expedition!<br />
          </p>
        </header>
        <section className="spotlight">
          <div className="image">
            <img src={natalie} alt="" />
          </div>
          <div className="content">
            <h2>
              Natalie
            </h2>
            <p>
            Exploring the great outdoors behind the wheel, discovering all things nature with my boys, and sharing moments with fluffy companions.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={gavin} alt="" />
          </div>
          <div className="content">
            <h2>
              Gavin
            </h2>
            <p>
            Passionate about offroading, car enthusiast, & photographer capturing our adventures one photo (or video) at a time. 
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={william} alt="" />
          </div>
          <div className="content">
            <h2>
              William
            </h2>
            <p>
              Construction aficionado captivated by all things machinery-related! Blaze and the Monster Machines enthusiast and finds mud mishaps absolutely hilarious.
            </p>
          </div>
        </section>
        <section className="spotlight">
          <div className="image">
            <img src={theo} alt="" />
          </div>
          <div className="content">
            <h2>
              Theo
            </h2>
            <p>
              Loves running, sand play, and the machines of construction (just a touch of sibling rivalry). Always eager to jump into the action!
            </p>
          </div>
        </section>
      </div>
    </section>

    <section id="why" className="wrapper style2 special">
      <div className="inner">
        <header className="major">
          <h2>
            Why Expedition Explore?
          </h2>
          <p>
            <em>Expedition Explore</em> embodies the spirit of adventurous exploration and discovery. It’s a journey to uncover new places, knowledge, and experiences, involving curiosity, courage, and a quest for the unknown. It evokes the idea of setting out on expeditions to explore new places.
          </p>
        </header>
      </div>
    </section>

  </Layout>
);

export default IndexPage;
